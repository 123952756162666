<template>
  <v-card class="" :color="ant">
    <v-card-title>
      <v-badge
        bordered
        bottom
        color="deep-purple accent-4"
        dot
        offset-x="10"
        offset-y="10"
      >
        <v-avatar color="grey">
          <img
						v-if="avatarURl"
            :src="avatarURl"
            alt="John"
          />
					<span v-else class='text-h3'> {{ order.customer.nickname ? trimFirtTwo(order.customer.nickname) :  order.customer.id  }} </span>
        </v-avatar>
      </v-badge>
      <div class="text-body-2 ml-2 d-flex flex-column">
         <span> {{ order.customer.nickname ? order.customer.nickname: order.customer_id }} </span>
        <span> Customer </span>
      </div>
      <v-spacer></v-spacer>
      <div class="text-body-2 d-flex flex-column">
        <span class="align-self-end">
          <flag :iso="order.customer.country_code ? order.customer.country_code.toLowerCase() : 'NA'" /> {{ customerTime ? customerCurrentTime : 'N/A' }}</span
        >
        <span>Customer Local Time</span>
      </div>
    </v-card-title>
  </v-card>
</template>

<script>
import generalMxn from '@/mixins/general_mixin';
import colorMxn from "@/mixins/colorMxn";
import timezoneMxn from '@/mixins/timezone_mixin';

export default {
  name: "customerLocal",
  props: ["order"],
  mixins: [colorMxn, timezoneMxn, generalMxn],
  data() {
      return {
      };
    },
  computed: {
    avatarURl() {
      // const url = this.order.customer.avatar ? `${process.env.VUE_APP_BASE_URL}/avatar/download/${this.order.customer.avatar}` : null;
      return this.order.customer.avatar;
    },
	},
  mounted() {
    this.getCustomerTime();
  }
};
</script>
